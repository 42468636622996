import Vue from 'vue';
import VueRouter from 'vue-router';
import Subsidiaries from '@/views/subsidiaries/Subsidiaries.vue';
import SubsidiaryDetail from '@/views/subsidiaries/subsidiary/SubsidiaryDetail.vue';
import Tenants from '@/views/tenants/Tenants.vue';
import EndCustomers from '@/views/endCustomers/EndCustomers.vue';
import TenantDetail from '@/views/tenants/tenant/TenantDetail.vue';
import EndCustomerDetail from '@/views/endCustomers/endCustomer/EndCustomerDetail.vue';
import Unauthorized from '@/views/Unauthorized.vue';
import Home from '@/views/Home.vue';
import UsersWithParent from '@/views/users/UsersWithParent.vue';
import Login from '@/components/authentication/Login.vue';
import Logout from '@/components/authentication/Logout.vue';
import { AccessLevel } from '@/models/user';
import store from '@/store';
import { hasPermission } from '@/utils/permissionUtils';
import { ToastProgrammatic as Toast } from 'buefy';
import i18n from '@/i18n';
import { PersistentGetters } from '@/store/persistent/enums';
import Dashboard from '@/views/dashboard/dashboardWithParent.vue';
import QuoteParent from '@/views/dashboard/QuoteParent.vue';
import QuoteParentNew from '@/views/dashboard/QuoteParentNew.vue';
import { BasePath } from '@/models/constants';
import QuoteList from '@/views/quotes/QuoteList.vue';
import SaleOrderList from '@/views/saleOrders/SaleOrderList.vue';
import Logs from '@/views/logs/Logs.vue';
import LogDetail from '@/views/logs/LogDetail.vue';

Vue.use(VueRouter);

export enum Routes {
  Dashboard = 'dashboard',
  Home = 'home',
  Devices = 'devices',
  Subsidiaries = 'subsidiaries',
  SubsidiaryDetail = 'subsidiaries-detail',
  Tenants = 'tenants',
  TenantDetail = 'tenant-detail',
  Reports = 'report',
  Users = 'users',
  Unauthorized = 'unauthorized',
  Register = 'register',
  Profile = 'profile',
  Login = 'login',
  LoginFailed = 'login-failed',
  LoginCallback = 'login-callback',
  Logout = 'logout',
  LogoutCallback = 'logout-callback',
  LoggedOut = 'loggedOut',
  EndCustomers = 'end-customers',
  EndCustomerDetail = 'end-customer-detail',
  QuoteParent = 'quote',
  SaleOrder = 'sale-order',
  Quotes = 'quotes',
  SaleOrders = 'orders',
  Logs = 'logs',
  LogDetail = 'log-detail',
}

const routes = [
  {
    path: '/Home',
    name: Routes.Home,
    component: Home,
  },
  {
    path: '/subsidiaries',
    name: Routes.Subsidiaries,
    component: Subsidiaries,
    meta: {
      permissions: [AccessLevel.SubsidiaryViewer],
    },
  },
  {
    path: '/subsidiaries/detail',
    name: Routes.SubsidiaryDetail,
    component: SubsidiaryDetail,
    meta: {
      permissions: [AccessLevel.SubsidiaryViewer],
    },
  },
  {
    path: '/tenants',
    name: Routes.Tenants,
    component: Tenants,
    meta: {
      permissions: [AccessLevel.TenantViewer],
    },
  },
  {
    path: '/tenants/detail',
    name: Routes.TenantDetail,
    component: TenantDetail,
    meta: {
      permissions: [AccessLevel.TenantViewer],
    },
  },
  {
    path: '/end-customers',
    name: Routes.EndCustomers,
    component: EndCustomers,
    meta: {
      permissions: [
        AccessLevel.TenantAdmin,
        AccessLevel.EndCustomerAdmin,
        AccessLevel.EndCustomerGlobal,
        AccessLevel.EndCustomerUser,
      ],
    },
  },
  {
    path: '/dashboard/:endCustomerId?',
    name: Routes.Dashboard,
    component: Dashboard,
  },
  {
    path: '/quote/:id?',
    name: Routes.QuoteParent,
    component: QuoteParent,
  },
  {
    path: '/sale-order/:id?',
    name: Routes.SaleOrder,
    component: QuoteParent,
  },
  {
    path: '/end-customer/detail',
    name: Routes.EndCustomerDetail,
    component: EndCustomerDetail,
    meta: {
      permissions: [AccessLevel.TenantViewer],
    },
  },
  {
    path: '/users',
    name: Routes.Users,
    component: UsersWithParent,
    meta: {
      permissions: [AccessLevel.SuperAdmin],
    },
  },
  {
    path: '/unauthorized',
    name: Routes.Unauthorized,
    component: Unauthorized,
  },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/authentication/register',
    name: Routes.Register,
    component: Login,
  },
  {
    path: '/authentication/profile',
    name: Routes.Profile,
    component: Login,
  },
  {
    path: '/authentication/login',
    name: Routes.Login,
    component: Login,
  },
  {
    path: '/authentication/login-failed',
    name: Routes.LoginFailed,
    component: Login,
  },
  {
    path: '/authentication/login-callback',
    name: Routes.LoginCallback,
    component: Login,
  },
  {
    path: '/authentication/logout',
    name: Routes.Logout,
    component: Logout,
  },
  {
    path: '/authentication/logged-out',
    name: Routes.LoggedOut,
    component: Logout,
  },
  {
    path: '/authentication/logout-callback',
    name: Routes.LogoutCallback,
    component: Logout,
  },
  {
    path: '/quotes',
    name: Routes.Quotes,
    component: QuoteList,
  },
  {
    path: '/orders',
    name: Routes.SaleOrders,
    component: SaleOrderList,
  },
  {
    path: '/logs',
    name: Routes.Logs,
    component: Logs,
  },
  {
    path: '/log/detail/:id?',
    name: Routes.LogDetail,
    component: LogDetail,
  },
];

const router = new VueRouter({
  base: BasePath,
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.permissions)) {
    if (
      to.meta && to.meta.permissions
        .filter((permission: any) => hasPermission(store.getters[PersistentGetters.CurrentUser], permission))
        .length > 0
    ) {
      next();
    } else {
      Toast.open({
        duration: 5000,
        message: i18n.t('route.unauthorized_message').toString(),
        position: 'is-top',
        type: 'is-danger',
      });
    }
  } else {
    next();
  }
});
export default router;
