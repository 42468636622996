
    import { Routes } from '@/router';
    import { Component, Ref, Vue } from 'vue-property-decorator';
    import { Log } from '@/models/log';
    import { logsService } from '@/services/logs.service';

    @Component({
        components: {},
    })
    export default class LogDetail extends Vue {
        public id: number = 0;
        public log: Log | null = null;
        public selectedTab: number = 0;

        public async created(): Promise<void> {
            if (!this.$route.params || !this.$route.params.id) {
                this.$router.push({ name: Routes.Logs });
            } else {
                this.id = +this.$route.params.id;
                await this.setById(this.id);
            }
        }

        public async setById(id: number) {
            this.$spinner.showSpinner();
            this.log = await logsService.getById(id);
            this.$spinner.removeSpinner();
        }
    }
