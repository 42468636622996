import * as signalR from '@microsoft/signalr';

class SignalRService {
    public connection: signalR.HubConnection;
    constructor() {
        const getUrl = window.location;
        const baseUrl = getUrl.protocol + '//' + getUrl.host;
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${baseUrl}/notificationHub`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .build();

        // this.start();
    }

    public async start() {
        try {
            if (this.connection.state === signalR.HubConnectionState.Disconnected) {
                await this.connection.start();
                console.log('SignalR Connected.');
            }
        } catch (err) {
            console.error('Error connecting to SignalR: ', err);
            setTimeout(() => this.start(), 5000);
        }
    }

    public async registerUser(userId: string) {
        try {
            await this.connection.invoke('RegisterUser', userId);
            console.log('User Registered.');
        } catch (err) {
            console.error('SignalR RegisterUser Error: ', err);
        }
    }
}

export default new SignalRService();
