import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { Log } from '@/models/log';

class LogsService {
  public get(): Promise<Log[]> {
    return axios.get<Log[]>(`${BaseUrl}/logs`).then((response: any) => {
      return response.data;
    });
  }

  public getById(id: number): Promise<Log> {
    return axios.get<Log>(`${BaseUrl}/logs/${id}`).then((response: any) => {
      return response.data;
    });
  }
}

export const logsService = new LogsService();
