
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Routes } from '@/router';
import Button from '@/components/Button.vue';
import { logsService } from '@/services/logs.service';
import { Log } from '@/models/log';

@Component({
    components: {
        'sc-button': Button,
    },
})
export default class Logs extends Vue {

    public logs: Log[] = [];
    public logsOriginal: Log[] = [];
    public isPaginationSimple = false;
    public paginationPosition = 'bottom';
    public defaultSortDirection = 'desc';
    public sortIcon = 'arrow-up';
    public sortIconSize = 'is-small';
    public currentPage = 1;
    public perPage = 10;
    public isPaginated = true;
    public routes = Routes;
    public onlyError: boolean = false;

    public async created() {
        await this.getLogs();
    }

    public reload() {
        this.logs = [];
        this.getLogs();
    }

    public filter(row: any, input: string) {
        console.log(this.onlyError);
        if (!this.onlyError) {
            this.logs = this.logs.filter((log) => log.isError === true);
        } else {
            this.logs = this.logsOriginal;
        }
    }

    public filterDate(row: any, input: string) {
        if (input === null || input === undefined) {
            return false;
        }
        const date = row.date;
        if (date === null || date === undefined) {
            return false;
        }

        const localisedInput = input.toLocaleLowerCase();
        return date.toLocaleDateString().includes(localisedInput);
    }

    private async getLogs() {
        this.$spinner.showSpinner();
        logsService.get()
            .then((response) => {
                this.logs = response;
                this.logsOriginal = response;
            })
            .finally(() => this.$spinner.removeSpinner());
    }

    private details(id: number) {
        this.$router.push({ name: Routes.LogDetail, params: { id: id.toString() } });
    }
}
