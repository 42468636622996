
import { Component, Vue, Ref } from 'vue-property-decorator';
import Button from '@/components/Button.vue';

import { Routes } from '@/router';

import { QuoteActions } from '@/store/quote/enums';
import { PersistentGetters } from '@/store/persistent/enums';

import { Quote } from '@/models/quote';
import { SaleOrder } from '@/models/saleOrder';
import { QuotePart, Material as IMaterial, Technology as ITechnology, MaterialFormat, Material } from '@/models/QuotePart';
import { MaterialGroup } from '@/models/materialGroup';
import { Technology } from '@/models/technology';
import { Address } from '@/models/address';
import { QuoteStatusEnum } from '@/models/enums/QuoteStatusEnum';

import { quotesService } from '@/services/quotes.service';

import AddPartFile from '@/components/quote/AddPartFile.vue';
import RejectQuote from '@/components/quote/RejectQuote.vue';
import Guidelines from '@/components/quotes/Guidelines.vue';
import { saleOrdersService } from '@/services/saleOrders.service';
import SaleOrderInvoices from '../../components/saleOrders/SaleOrderInvoices.vue';

import LocalSpinner from '@/components/LocalSpinner.vue';
import PartComponent from '@/components/quote/QuotePart.vue';
import QuoteShippingSelect from '@/components/quote/QuoteShippingSelect.vue';
import LeadTimeInfo from '@/components/quote/LeadTimeInfo.vue';
import QuoteSummary from '@/components/quote/QuoteSummary.vue';
import { MeasurementActions } from '@/models/enums/MeasurementActions';
import EditQuoteParts from '@/components/quote/EditQuoteParts.vue';
import BySoftBusinessQuote from '@/components/quote/BySoftBusinessQuote.vue';

@Component({
  components: {
    'sc-file-upload': AddPartFile,
    'sc-reject-quote': RejectQuote,
    'sc-quotes-guidelines': Guidelines,
    'sc-button': Button,
    'sc-invoices-modal': SaleOrderInvoices,
    'sc-local-spinner': LocalSpinner,
    'sc-quote-part': PartComponent,
    'sc-shipping-address-select': QuoteShippingSelect,
    'sc-lead-time-info': LeadTimeInfo,
    'sc-quote-summary': QuoteSummary,
    'sc-edit-quote-parts': EditQuoteParts,
    'sc-bysoft-business-quote': BySoftBusinessQuote,
  },
})
export default class QuoteParent extends Vue {
  @Ref('rejectQuote')
  public rejectQuoteComponent!: RejectQuote;

  @Ref('bySoftBusinessQuote')
  public bySoftBusinessQuote!: BySoftBusinessQuote;

  @Ref('saleOrderInvoices')
  public invoicesModal!: SaleOrderInvoices;

  public saleOrder: SaleOrder = SaleOrder.GetEmpty();

  public isServiceModalActive: boolean = false;
  public isProductionTimesModalActive: boolean = false;
  public isCalculateButtonEnabled = true;
  public showCalculationSpinner = false;

  public savingPart = false;
  public changingDeliveryAddress = false;

  public changesAdded: boolean = false;
  public changesAddedToCalculate: boolean = false;
  public globalCheck: boolean = false;
  public anyPartSelected: boolean = false;

  public modalVisible = false;
  public selectedPartsToEdit: string = '';

  public get quote(): Quote {
    return this.$store.state.quote.quote;
  }

  public set quote(quote: Quote) {
    this.$store.dispatch(QuoteActions.SetQuote, quote);
  }

  public async created(): Promise<void> {
    this.$spinner.showSpinner();
    this.inputsPartsChanged(null);

    if (!this.$route.params || !this.$route.params.id) {
        this.$router.push({ name: Routes.Dashboard });
    }

    const promise: Promise<Quote> = quotesService.getById(+this.$route.params.id);

    try {
      const quote = await promise;
      this.isCalculateButtonEnabled = this.quoteIsDraft;
      this.quote = quote;
      quote.parts.forEach((part) => {
        part.originalArea = part.area;
        part.originalLength = part.length;
        part.originalWidth = part.width;
        part.originalTechnologies = [...part.technologies];
        this.getPossibleMaterials(part);
        this.getPossibleMaterialFormats(part);
        this.setMeasurementUnits(part);
        // this.setSelectedTechnologies(part);
        part.defaultMeasurement = 0;
      });

      if (quote.saleOrderId) {
        this.saleOrder = await saleOrdersService.getById(quote.saleOrderId);
      }
      if (quote.shippingAddress) {
        quote.addressId = quote.shippingAddress.id;
      }

      this.quote = quote;
    } finally {
      this.changesAdded = false;
      this.changesAddedToCalculate = false;
      this.$spinner.removeSpinner();
    }
  }

  /* Reject Quote */
  public NavigateAfterRejected() {
    this.$router.push({ name: Routes.Dashboard });
  }

  public get quoteIsDraft(): boolean {
    return this.quote.status === 10;
  }

  public async onPartAdded(files: File[]) {
    if (this.quote.isIntegratedWithERPSystem !== null) {
      this.$spinner.showSpinner();

      if (this.quote.isIntegratedWithERPSystem) {
        quotesService
        .addParts(this.quote.id, files)
        .then((response: any) => {
          this.bySoftBusinessQuoteMessage(true);
        })
        .finally(() => this.$spinner.removeSpinner())    ;
      } else {
        quotesService
        .addPart(this.quote.id, files[0])
        .then((response: any) => {
          this.$store.dispatch(QuoteActions.AddPart, response.data);
          this.inputsPartsChanged(null);

          if (response && response.data && response.data.length > 0) {
            response.data.forEach((part: QuotePart) => {
              this.getPossibleMaterials(part);
              this.getPossibleMaterialFormats(part);
              this.setMeasurementUnits(part);
              // this.setSelectedTechnologies(part);
            });
          }
        })
        .finally(() => this.$spinner.removeSpinner());
      }
    }
  }

  public get stateQuote() {
    return this.$store.state.quote;
  }

  public get quoteHasParts(): boolean {
    if (!this.quote.parts) {
      return false;
    }
    return this.quote.parts.length > 0;
  }

  /* -- Quote masters setup -- */
  public get materialGroups(): MaterialGroup[] {
    return this.$store.getters[PersistentGetters.GetMaterialGroups] ?? [];
  }

  public get shippingAddresses(): Address[] {
    const addresses = (this.$store.getters[PersistentGetters.GetAddresses] as Address[]) ?? [];
    if (addresses.length === 0) {
      return [];
    }
    return addresses.filter((a) => a.type === 0);
  }

  public get technologies(): Technology[] {
    return this.$store.getters[PersistentGetters.GetTechnologies] ?? [];
  }

  /* Sale Order Specific */
  public get isSaleOrder() {
    return this.$route.name === Routes.SaleOrder;
  }

  public togglePartIsCollapsed(part: QuotePart) {
    part.isCollapsed = !part.isCollapsed;
    this.$store.dispatch(QuoteActions.SetPartIsCollapsed, part);
  }

  public getPrettyResponse(response: string) {
    const obj = JSON.parse(response);
    return JSON.stringify(obj, null, '\t').toString();
  }

  /* -- Part deletion -- */
  public confirmDelete(part: QuotePart): void {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_title').toString(),
      message: this.$i18n.t('dialog.confirm_delete').toString() + ` <b>${part.fileName}</b>?`,
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        this.deletePart(part);
      },
    });
  }

  public async deletePart(part: QuotePart) {
    this.inputsPartsChanged(null);

    this.$spinner.showSpinner();
    await quotesService.deletePart(part.id);
    this.$store.dispatch(QuoteActions.RemovePart, part);

    if (!this.quote.parts || this.quote.parts.length <= 0) {
      const quote = await quotesService.getById(this.quote.id);
      this.quote = quote;
    }
    // const quote = await quotesService.getById(this.quote.id);

    // quote.parts.forEach((p) => {
    //   this.getPossibleMaterials(p);
    //   this.getPossibleMaterialFormats(p);
    //   this.setMeasurementUnits(p);
    //   this.setSelectedTechnologies(p);
    // });

    // this.quote = quote;
    this.$spinner.removeSpinner();
  }

  /* -- Deal with part parameters change --*/
  public onMaterialGroupChanged(part: QuotePart) {
    this.inputsPartsChanged(part);

    if (part.materialId) {
      part.materialId = 0;
    }

    if (part.materialGroupId === -1) {
      this.setErrorVariables(part, true, true);
    } else {
      this.setErrorVariables(part, false, true);
    }

    // part.price = 0;
    const selectedMaterialGroup = this.materialGroups.filter((m) => m.id === part.materialGroupId);
    if (selectedMaterialGroup.length <= 0) {
      part.possibleMaterials = [];
      part.possibleMaterialFormats = [];
      return;
    }

    const groupMaterials = selectedMaterialGroup[0].materials;
    if (groupMaterials && groupMaterials !== null) {
      part.possibleMaterials = groupMaterials as unknown as IMaterial[];
      part.possibleMaterialFormats = [];
      return;
    }

    part.possibleMaterials = [];
  }

  public getPossibleMaterials(part: QuotePart) {
    const index = this.materialGroups.findIndex((x) => x.id === -1);
    if (index >= 0) {
      this.materialGroups.splice(index, 1);
    }

    const selectedMaterialGroup = this.materialGroups.filter((m) => m.id === part.materialGroupId);

    if (selectedMaterialGroup.length <= 0) {
      part.possibleMaterialFormats = [];
      part.possibleMaterials = [];

      if (part.materialName) {
        const unknown: any = this.$t('sale_order.status.0');
        const newMaterialGroup: MaterialGroup = ({
          id: -1,
          name: unknown,
          description: '',
          materials: [],
          tenantId: 0,
        });
        this.materialGroups.push(newMaterialGroup);
        part.materialGroupId = -1;

        const newMaterial: Material = ({
          id: -1,
          name: part.materialName || unknown,
          groupId: 0,
          thicknesses: [0],
          density: 0,
          materialFormats: [],
        });
        part.possibleMaterials.push(newMaterial);
        part.materialId = -1;
      }

      return;
    }

    const groupMaterials = selectedMaterialGroup[0].materials;
    if (groupMaterials && groupMaterials !== null) {
      part.possibleMaterials = groupMaterials as unknown as IMaterial[];
      part.possibleMaterialFormats = [];
      return;
    }

    part.possibleMaterials = [];
  }

  public getPossibleMaterialFormats(part: QuotePart) {
    const selectedMaterial = part.possibleMaterials.filter((m) => m.id === part.materialId);
    if (selectedMaterial.length <= 0) {
      if (part.thickness !== 0) {
        const newFormat: MaterialFormat = ({
          id: -1,
          thickness: part.thickness || 0,
          materialId: -1,
          cost: 0,
        });
        this.setErrorVariables(part, true, false);
        part.possibleMaterialFormats.push(newFormat);
        return;
      }

      part.possibleMaterialFormats = [];
      return;
    }

    let materialMaterialFormats = selectedMaterial[0].materialFormats;
    if (materialMaterialFormats && materialMaterialFormats !== null && materialMaterialFormats.length > 0) {
      materialMaterialFormats = materialMaterialFormats.filter((obj) => obj.id !== -1);
      materialMaterialFormats = materialMaterialFormats.sort((a, b) => {
          return a.thickness - b.thickness;
      });

      part.possibleMaterialFormats = materialMaterialFormats;
      this.checkPMTQThicknessPart(part);
      return;
    } else {
      if (part.materialId === -1) {
        const newFormat: MaterialFormat = ({
          id: -1,
          thickness: part.thickness || 0,
          materialId: part.materialId,
          cost: 0,
        });

        this.setErrorVariables(part, true, true);
        part.possibleMaterialFormats.push(newFormat);
        return;
      }
    }

    part.possibleMaterialFormats = [];
  }

  public onMaterialChanged(part: QuotePart) {
    this.inputsPartsChanged(part);

    if (part.thickness) {
      part.thickness = 0;
    }

    const selectedMaterial = part.possibleMaterials.filter((m) => m.id === part.materialId);
    if (selectedMaterial.length <= 0) {
      part.possibleMaterialFormats = [];
      return;
    }

    let materialMaterialFormats = selectedMaterial[0].materialFormats;
    if (materialMaterialFormats && materialMaterialFormats !== null) {
      materialMaterialFormats = materialMaterialFormats.sort((a, b) => {
          return a.thickness - b.thickness;
      });

      part.possibleMaterialFormats = materialMaterialFormats;
      return;
    }

    part.possibleMaterialFormats = [];
  }

  public hasMaterialFormats(part: QuotePart) {
    return part.possibleMaterialFormats && part.possibleMaterialFormats.length > 0;
  }

  public async onThicknessChanged(part: QuotePart) {
    this.inputsPartsChanged(part);

    if (part.thickness && part.possibleMaterialFormats && part.possibleMaterialFormats.length > 0) {
      let formatError: boolean = false;
      part.possibleMaterialFormats.forEach((format) => {
        if (format.thickness === part.thickness && format.id === -1) {
          formatError = true;
          this.setErrorVariables(part, true, false);
          return;
        }
      });

      if (!formatError) {
        this.setErrorVariables(part, false, false);
      }
    }
  }

  public async onUnitsChanged(part: QuotePart) {
    this.inputsPartsChanged(part);
  }

  public async onTechnologiesChanged(part: QuotePart) {
    this.inputsPartsChanged(part);
  }

  public isValid(part: QuotePart) {
    return (
      part.materialGroupId > 0 &&
      part.materialId > 0 &&
      part.thickness > 0 &&
      part.units > 0 &&
      part.technologies.length > 0 &&
      !part.isMaterialError &&
      !part.isThicknessError
    );
  }

  /* -- Deal with shipping address selection change -- */
  public async onShippingAddressChanged(shippingAddressId: number) {
    this.inputsPartsChanged(null);

    const shippingAddress = this.shippingAddresses.find((a) => a.id === shippingAddressId) || null;
    this.quote.shippingAddress = shippingAddress;

    if (this.quote.shippingAddress) {
      this.quote.addressId = this.quote.shippingAddress.id;
    }
  }

  /* -- Enable Accept/Reject buttons  -- */
  public get canSave() {
    return this.quote.totalPrice > 0;
  }

  public get canCalculate() {
    if (!this.quote.shippingAddress || !this.changesAddedToCalculate) {
      return false;
    }
    return this.quote.parts.every((p) => this.isValid(p));
  }

  public get canSaveData() {
    return !this.changesAdded;
  }

  public async saveQuote() {
    this.$spinner.showSpinner();
    quotesService.save(this.quote)
      .then((response) => {
      this.changesAdded = false;
      this.changesAddedToCalculate = true;
      this.$store.dispatch(QuoteActions.SetQuote, response);
      this.quote = response;

      if (this.quote && this.quote.shippingAddress && this.quote.shippingAddress.id) {
          this.quote.addressId = this.quote.shippingAddress.id;
      }

      this.quote.parts.forEach((part) => {
          this.getPossibleMaterials(part);
          this.getPossibleMaterialFormats(part);
          this.setMeasurementUnits(part);
      });
    })
    .catch((error) => {
        this.$buefy.toast.open({
            duration: 5000,
            message: `${this.$i18n.t('business_central.save_order_failure')}`,
            type: 'is-danger',
        });
    })
    .finally(() => {
        this.$spinner.removeSpinner();
        this.globalCheck = false;
    });
  }

  /* -- Calculate order/quote -- */
  public async calculateQuote() {
    if (this.quote.isIntegratedWithERPSystem !== null) {
      this.$spinner.showSpinner();

      if (this.quote.isIntegratedWithERPSystem) {
        quotesService.update(this.quote).then((response) => {
          this.bySoftBusinessQuoteMessage(false);
        })
        .finally(() => this.$spinner.removeSpinner());
      } else {
        quotesService.update(this.quote)
        .then((response) => {
          this.changesAdded = false;
          this.changesAddedToCalculate = false;
          this.$store.dispatch(QuoteActions.SetQuote, response);
          this.quote = response;

          if (this.quote && this.quote.shippingAddress && this.quote.shippingAddress.id) {
            this.quote.addressId = this.quote.shippingAddress.id;
          }

          this.quote.parts.forEach((part) => {
            this.getPossibleMaterials(part);
            this.getPossibleMaterialFormats(part);
            this.setMeasurementUnits(part);
            // this.setSelectedTechnologies(part);
          });
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
              message: `${this.$i18n.t('business_central.calculate_order_failure')}`,
            type: 'is-danger',
          });
        })
        .finally(() => {
          this.$spinner.removeSpinner();
          this.globalCheck = false;
        });
      }
    }
  }

  /* -- Accept quote -- */
  public async acceptQuote() {

    this.acceptQuoteWithoutERP();
    // if (this.quote && this.quote.isIntegratedWithERPSystem) {
    //  this.acceptQuoteERP();
    // } else {
    //  this.acceptQuoteWithoutERP();
    // }
  }

  public async acceptQuoteERP() {
    this.$buefy.toast.open({
      duration: 5000,
      message: `${this.$i18n.t('no_available_errors.temporal_no_available')}`,
      type: 'is-danger',
    });
  }

  public async acceptQuoteWithoutERP() {
    const quote = this.quote;
    quote.status = QuoteStatusEnum.Accepted;
    this.$spinner.showSpinner();

    quotesService.updateStatus(this.quote.id, QuoteStatusEnum.Accepted, null)
      .then((response) => {
        this.$store.dispatch(QuoteActions.SetQuote, quote);
        this.$router.push({ name: Routes.Dashboard });
        this.$buefy.toast.open({
          duration: 5000,
          message: `${this.$i18n.t('business_central.accept_success')}`,
          type: 'is-success',
        });
        this.globalCheck = false;
      })
      .catch((error) => {
        this.$buefy.toast.open({
          duration: 5000,
          message: `${this.$i18n.t('business_central.accept_order_failure')}`,
          type: 'is-danger',
        });
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  /* -- Reject quote -- */
  public async rejectQuote() {
    this.rejectQuoteComponent.openModal(this.quote.id);
  }

  public partTitlePadding() {
    if (this.quoteIsDraft) {
      return '';
    }
    return 'padding-bottom:10px;';
  }

  public showReport(selectedQuote: Quote) {
    if (selectedQuote.isIntegratedWithERPSystem !== null) {
      this.$spinner.showSpinner();

      if (selectedQuote.isIntegratedWithERPSystem) {
        quotesService.showReport(selectedQuote.id)
            .then((response) => {
                const resp: any = response;
            })
            .catch((error) => {
                this.$buefy.toast.open({
                  duration: 5000,
                  message: `${this.$i18n.t('sale_order.show_report_error')}`,
                  type: 'is-danger',
                });
            })
            .finally(() => {
                this.$spinner.removeSpinner();
        });
      } else {
        quotesService.showReport(selectedQuote.id).finally(() => this.$spinner.removeSpinner());
      }
    } else {
      this.$buefy.toast.open({
        duration: 5000,
        message: `${this.$i18n.t('sale_order.tenant_error')}`,
        type: 'is-danger',
      });
    }
  }

  public showInvoices(saleOrderId: number) {
    this.invoicesModal.openModal(saleOrderId, this.quote.isIntegratedWithERPSystem);
  }

  public navigateToSaleOrder(id: number) {
    this.$router.push({ name: Routes.SaleOrder, params: { id: id.toString() } });
  }

  public setMeasurementUnits(part: QuotePart) {
    if (part && part.measurementUnit === 0) {
      part.unknownUnit = true;
    }

    if (Number(this.quote.measurementUnit) === 1) {
      part.lengthUnitDimensions = 'mm';
      part.lengthUnitArea = 'm';

      if (part.measurementUnit === 0 || part.measurementUnit === 1) {
        part.area = part.area / MeasurementActions.squareMilimetersToSquareMeters;
      } else if (part.measurementUnit === 2) {
        part.area = part.area * MeasurementActions.squareInchesToSquareMeters;
        part.width = part.width * MeasurementActions.inchesToMilimeters;
        part.length = part.length * MeasurementActions.inchesToMilimeters;
      }
    } else if (Number(this.quote.measurementUnit) === 2) {
      part.lengthUnitDimensions = 'inch';
      part.lengthUnitArea = 'inch';

      if (part.measurementUnit === 1) {
        part.area = part.area * MeasurementActions.squareMilimetersToSquareInches;
        part.width = part.width * MeasurementActions.millimetersToInches;
        part.length = part.length * MeasurementActions.millimetersToInches;
      }
    }
  }

  public onMeasurementUnitsChanged(part: QuotePart) {
    if (Number(this.quote.measurementUnit) === 1) {
      if (part.measurementUnit === 2) {
        part.area = part.originalArea * MeasurementActions.squareInchesToSquareMeters;
        part.width = part.originalWidth * MeasurementActions.inchesToMilimeters;
        part.length = part.originalLength * MeasurementActions.inchesToMilimeters;
      } else if (part.measurementUnit === 1) {
        if (part.defaultMeasurement !== 0) {
          part.area = part.originalArea / MeasurementActions.squareMilimetersToSquareMeters;
          part.width = part.originalWidth;
          part.length = part.originalLength;
          }
      }
    } else if (Number(this.quote.measurementUnit) === 2) {
      if (part.measurementUnit === 1) {
        part.area = part.originalArea * MeasurementActions.squareMilimetersToSquareInches;
        part.width = part.originalWidth * MeasurementActions.millimetersToInches;
        part.length = part.originalLength * MeasurementActions.millimetersToInches;
      } else if (part.measurementUnit === 2) {
          if (part.defaultMeasurement !== 0) {
            part.area = part.originalArea;
            part.width = part.originalWidth;
            part.length = part.originalLength;
          }
      }
    }
    this.changesAdded = true;
    this.changesAddedToCalculate = true;
    part.defaultMeasurement = part.measurementUnit;
  }

  public inputsPartsChanged(part: any) {
    this.changesAdded = true;
    this.changesAddedToCalculate = true;
    this.quote.processingPrice = 0;
    this.quote.shippingPrice = 0;
    this.quote.totalPrice = 0;

    if (part && part !== null) {
      part.unitPrice = 0;
      part.totalPrice = 0;
    }
  }

  public checkPMTQThicknessPart(part: QuotePart) {
    if (part.thickness && part.possibleMaterialFormats && part.possibleMaterialFormats.length > 0) {
      let found: boolean = false;

      part.possibleMaterialFormats.forEach((format) => {
        if (format.thickness === part.thickness) {
          found = true;
          return;
        }
      });

      if (!found) {
        const newFormat: MaterialFormat = ({
          id: -1,
          thickness: part.thickness,
          materialId: part.materialId,
          cost: 0,
        });

        this.setErrorVariables(part, true, false);
        part.possibleMaterialFormats.push(newFormat);
        part.possibleMaterialFormats = part.possibleMaterialFormats.sort((a, b) => {
          return a.thickness - b.thickness;
      });
      }
    }
  }

  public async setErrorVariables(part: QuotePart, error: boolean, isMaterial: boolean) {
    if (isMaterial) {
      part.isMaterialError = error;
    } else {
      part.isThicknessError = error;
    }

    if (error) {
      this.inputsPartsChanged(part);
    } else {
      if (this.quote.parts) {
        let errorsFound: boolean = false;
        this.quote.parts.forEach((p) => {
          if (p.id !== part.id && error) {
            errorsFound = true;
            return;
          }
        });

        if (errorsFound) {
          this.inputsPartsChanged(part);
        }
      }
    }
  }

  // Multibar global actions
  public deleteSelectedPartsConfirmation(): void {
    if (this.quote && this.quote.parts && this.quote.parts.length > 0) {
      let deleteMessage: string = '';

      this.quote.parts.forEach((part) => {
        if (part.isSelected) {
          if (deleteMessage !== '') {
            deleteMessage += ', ';
          }

          deleteMessage += part.fileName;
        }
      });

      if (deleteMessage !== '') {
        this.$buefy.dialog.confirm({
          title: this.$i18n.t('dialog.confirm_title').toString(),
          message: this.$i18n.t('dialog.confirm_delete').toString() + ` <b>${deleteMessage}</b>?`,
          confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
          cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.deleteSelectedParts();
          },
        });
      }
    }
  }

  public async deleteSelectedParts() {
    if (this.quote && this.quote.parts && this.quote.parts.length > 0) {
      this.inputsPartsChanged(null);
      this.$spinner.showSpinner();
      const parts: number[] = [];

      this.quote.parts.forEach((part) => {
        if (part.isSelected) {
          parts.push(part.id);
        }
      });

      await quotesService.deleteParts(parts);
      this.$store.dispatch(QuoteActions.RemoveParts, parts);

      if (!this.quote.parts || this.quote.parts.length <= 0) {
        const quote = await quotesService.getById(this.quote.id);
        this.quote = quote;
      }
      this.$spinner.removeSpinner();
      this.globalCheck = false;
      this.anyPartSelected = false;
    }
  }

  public globalCheckboxChanged() {
    if (this.quote && this.quote.parts && this.quote.parts.length > 0) {
      this.quote.parts.forEach((part) => {
        if (this.globalCheck) {
          part.isSelected = true;
        } else {
          part.isSelected = false;
        }
      });
    }

    this.checkSelectedParts();
  }

  public expandSelectedParts() {
    if (this.quote && this.quote.parts && this.quote.parts.length > 0) {
      this.quote.parts.forEach((part) => {
        if (part.isSelected) {
          part.isCollapsed = false;
        }
      });
    }
  }

  public collapseSelectedParts() {
    if (this.quote && this.quote.parts && this.quote.parts.length > 0) {
      this.quote.parts.forEach((part) => {
        if (part.isSelected) {
          part.isCollapsed = true;
        }
      });
    }
  }

  public async selectedPartChanged(part: QuotePart) {
    part.isSelected = !part.isSelected;
    this.checkSelectedParts();
  }

  public async checkSelectedParts() {
    let found: boolean = false;
    let totalFound: number = 0;

    this.quote.parts.forEach((part) => {
      if (part.isSelected) {
        this.anyPartSelected = true;
        totalFound++;
        found = true;
      }
    });

    if (found) {
      this.anyPartSelected = true;

      if (totalFound === this.quote.parts.length) {
        this.globalCheck = true;
      }
    } else {
      this.anyPartSelected = false;
      this.globalCheck = false;
    }
  }

  public get multibarEnabled() {
    if (this.globalCheck) {
      return true;
    }

    if (this.quote.parts && this.quote.parts.length > 0) {
      const newArray = this.quote.parts.filter((part) => part.isSelected);
    }
  }

  public async editSelectedParts() {
    this.modalVisible = true;
    let stringParts: string = '';

    if (this.quote && this.quote.parts && this.quote.parts.length > 0) {
      let index: number = 0;
      this.quote.parts.forEach((part) => {
        if (part.isSelected) {
          if (index !== 0) {
            stringParts += ', ';
          }

          stringParts += part.fileName;
          index++;
        }
      });
    }

    this.selectedPartsToEdit = stringParts;
  }

  public closeModal() {
    this.modalVisible = false;
    this.$emit('onClose');
  }

  public async editSelectedPartsChanged(
    editedMaterialGroup: number,
    editedMaterial: number,
    editedThickness: number,
    editedUnits: number,
    editedMeasurementUnit: number,
    editedTechnologiesChanged: boolean,
    editedTechnologies: any []) {

    this.$spinner.showSpinner();

    if (this.quote && this.quote.parts && this.quote.parts.length > 0) {
      for (const part of this.quote.parts) {
        if (part.isSelected) {
          if (editedMaterialGroup && editedMaterialGroup > 0) {
            part.materialGroupId = editedMaterialGroup;
            await this.onMaterialGroupChanged(part);
          }
          if (editedMaterial && editedMaterial > 0) {
            part.materialId = editedMaterial;
            await this.onMaterialChanged(part);
          }
          if (editedThickness && editedThickness > 0) {
            part.thickness = editedThickness;
            await this.onThicknessChanged(part);
          }
          if (editedUnits && editedUnits > 0) {
            part.units = editedUnits;
            await this.onUnitsChanged(part);
          }
          if (part.unknownUnit && editedMeasurementUnit && editedMeasurementUnit > 0) {
              part.measurementUnit = editedMeasurementUnit;
              await this.onMeasurementUnitsChanged(part);
          }
          if (editedTechnologiesChanged) {
            if (part.technologies && part.technologies.length > 0) {
              for (const technology of part.technologies) {
                if (technology.id === 1 || technology.id === 2) {
                  editedTechnologies.push(technology);
                }
              }
            }

            part.technologies = editedTechnologies;
            await this.onTechnologiesChanged(part);
          }
        }
      }
    }

    this.$spinner.removeSpinner();
    this.showSuccessMessage();
    this.closeModal();
  }

  private showSuccessMessage() {
    const saveSuccess = this.$i18n.t('quotes.edit_selected_parts.save').toString();
    this.$buefy.toast.open({ message: saveSuccess, type: 'is-success' });
  }

  /* -- Show BySoft Business quote info messages -- */
  private async bySoftBusinessQuoteMessage(isImporting: boolean) {
    this.bySoftBusinessQuote.openModal(isImporting);
  }
}
